var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('header',{staticClass:"button_S"},_vm._l((_vm.tab),function(item,index){return _c('el-button',{key:index,attrs:{"type":_vm.active == index ? 'primary' : ''},on:{"click":function($event){return _vm.tabs(index)}}},[_vm._v(_vm._s(item)+" ")])}),1),_c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content",attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading"}},[_c('ul',{staticClass:"flex"},[_vm._l((_vm.jobFairDzList),function(item){return _c('li',{key:item.id,staticClass:"cursorP",on:{"click":function($event){return _vm.goRouter(
            '/videoJob',
            item.id,
            item.name,
            item.provinceid,
            item.cityid,
            item.moreUrl
          )}}},[_c('div',{staticClass:"imgs flexs"},[_c('img',{staticClass:"err_image",attrs:{"src":item.imgUrl,"title":item.title,"alt":item.title}})]),_c('div',{staticClass:"texts"},[_c('h3',{staticClass:"line_clamp1"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"flex p1"},[(item.ingType == 1)?_c('span',{staticClass:"flexs span2"},[_vm._v("未开始")]):_vm._e(),(item.ingType == 2)?_c('span',{staticClass:"flexs span1"},[_vm._v("进行中")]):_vm._e(),(item.ingType == 3)?_c('span',{staticClass:"flexs span3"},[_vm._v("已结束")]):_vm._e(),_vm._v(" "+_vm._s(item.sdate)+"-"+_vm._s(item.edate)+" ")]),_c('div',{staticClass:"flex"},[_c('p',{staticClass:"p2"},[_vm._v(" 企业 "),_c('span',{staticClass:"span2"},[_vm._v(_vm._s(item.jobFairStatics.companyCount || '0'))]),_vm._v(" 家 ")]),_c('p',{staticClass:"p2"},[_vm._v(" 职位 "),_c('span',{staticClass:"span2"},[_vm._v(_vm._s(item.jobFairStatics.jobCount))]),_vm._v("个 ")]),_c('p')])])])}),(_vm.isStatus)?_c('status',{attrs:{"type":"result"}}):_vm._e()],2)]),_c('div',{staticClass:"pages"},[_c('el-pagination',{staticClass:"work_pagination",attrs:{"background":"","current-page":_vm.params.pageNum,"page-size":_vm.params.pageSize,"layout":"prev, pager, next, total, jumper","prev-text":"上一页","next-text":"下一页","total":_vm.params.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.params, "pageNum", $event)},"update:current-page":function($event){return _vm.$set(_vm.params, "pageNum", $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }