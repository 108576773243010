<template>
  <div class="wrapper">
    <header class="button_S">
      <el-button
          :type="active == index ? 'primary' : ''"
          v-for="(item, index) in tab"
          :key="index"
          @click="tabs(index)"
      >{{ item }}
      </el-button
      >
    </header>
    <section
        class="content"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
    >
      <ul class="flex">
        <li
            v-for="item in jobFairDzList"
            :key="item.id"
            class="cursorP"
            @click="
            goRouter(
              '/videoJob',
              item.id,
              item.name,
              item.provinceid,
              item.cityid,
              item.moreUrl
            )
          "
        >
          <div class="imgs flexs">
            <img :src="item.imgUrl" :title="item.title" :alt="item.title" class="err_image"/>
          </div>
          <div class="texts">
            <h3 class="line_clamp1">{{ item.name }}</h3>
            <p class="flex p1">
              <span class="flexs span2" v-if="item.ingType == 1">未开始</span>
              <span class="flexs span1" v-if="item.ingType == 2">进行中</span>
              <span class="flexs span3" v-if="item.ingType == 3">已结束</span>
              {{ item.sdate }}-{{ item.edate }}
            </p>
            <div class="flex">
              <p class="p2">
                企业
                <span class="span2">{{
                    item.jobFairStatics.companyCount || '0'
                  }}</span>
                家
              </p>
              <p class="p2">
                职位
                <span class="span2">{{ item.jobFairStatics.jobCount }}</span
                >个
              </p>
              <p></p>
            </div>
          </div>
        </li>
        <status type="result" v-if="isStatus"></status>
      </ul>
    </section>
    <div class="pages">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="prev, pager, next, total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :total="params.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import status from "@/components/public/status";

export default {
  name: "onlineJobfair",
  components: {
    status,
  },
  metaInfo() {
    return {
      meta: this.meta,
    };
  },
  data() {
    return {
      tab: ["全部", "进行中", "已结束"],
      status: "1",
      loading: false,
      active: 0,
      isGoing: "0",
      jobFairDzList: [],
      params: {
        ingType: "",
        pageNum: 1,
        pageSize: 12,
        total: 0,
      },
      ingType: false,
      ingTypes: false,
      starttime: "",
      endtime: "",
      a: "",
      b: "",
      isStatus: false,
      location: '',
      meta: []
    };
  },
  methods: {
    tabs(index) {
      this.active = index;
      if (this.active == 0) {
        (this.params.ingType = ""), this.getJobFairDzList();
      } else if (this.active == 1) {
        this.params.ingType = 2;
        this.getJobFairDzList();
      } else {
        this.params.ingType = 3;
        this.getJobFairDzList();
      }
    },

    // getJobFairDzList
    async getJobFairDzList() {
      this.loading = true;
      try {
        let res = await this.$api.getJobFairDzList(this.params);
        console.log(res.data.records, 999666333)
        if (res.data.success) {
          this.params.total = res.data.data.total;
          this.jobFairDzList = res.data.data.records;
          this.getLocation();
          if (this.jobFairDzList.length <= 0) {
            this.isStatus = true;
          }
          let arrs = [];
          let arr = [];
          let a = [];
          let b = [];
          let myDate = Date.parse(new Date());
          this.jobFairDzList.forEach((element) => {
            this.starttime = Date.parse(element.sdate);
            this.endtime = Date.parse(element.edate);
            arr.push(this.starttime);
            arrs.push(this.endtime);
            arrs.forEach((rep) => {
              b.push(rep > myDate);
              b.forEach((element) => {
                this.ingType = element;
                return;
              });
            });
            arr.forEach((res) => {
              // a.push(res > myDate);
              // this.jobFairDzList.push({ ins: res > myDate });
              // a.forEach((element) => {
              //   // if (element == true) {
              //   this.ingTypes = element;
              //   // } else {
              //   //   this.ingTypes = 3;
              //   // }
              // });
            });
            // console.log(this.jobFairDzList, "ssss");

          });
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        this.loading = false;
      }
    },
    goRouter(url, id, name, provinceid, cityid, link) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          name: name,
          provinceid: provinceid,
          cityid: cityid,
        },
      });
      if (link) {
        window.open(link, "_blank");
      } else {
        window.open(href, "_blank");
      }
    },
    //分页
    handleCurrentChange: function (val) {
      this.params.pageNum = val;
      this.getJobFairDzList();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    getLocation() {
      this.location = localStorage.getItem('locationSite') || '';
      if (this.location == '') {
        document.title = '招聘会-兰州市残疾人就业创业网络服务平台';
        this.meta.push(
            {
              name: "keywords",
              content: '招聘会,残疾人就业,中国残疾人找工作,中国助残,残疾人就业,残疾人福利,中国残疾人就业服务',
            },
            {
              name: "description",
              content: '中国残疾人招聘会，汇集鬼片内最新网络及线下残疾人招聘会信息，为中国残疾人提供真实准确的求职信息及公司简介,帮助残疾人更好更快更全面地了解行业招聘信息。中国残疾人求职、找工作,上兰州市残疾人就业创业网络服务平台'
            }
        );
      } else if (this.location == '甘肃省') {
        document.title = '招聘会-兰州市残疾人就业创业网络服务平台';
        this.meta.push(
            {
              name: "keywords",
              content: '兰州招聘会,兰州残疾人就业,兰州残疾人找工作,兰州助残,残疾人就业,残疾人福利,兰州残疾人就业服务,兰州市残联',
            },
            {
              name: "description",
              content: '兰州市残疾人招聘会，汇集兰州市最新网络及线下残疾人招聘会信息，为兰州市残疾人提供真实准确的求职信息及公司简介,帮助残疾人更好更快更全面地了解行业招聘信息。兰州市残疾人求职、找工作,上兰州市残疾人就业创业网络服务平台'
            }
        );
      }
    }

  },
  created() {
    this.tabs(0);
    this.$emit("goRouter", "/hunan/skill/");
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/onjobFair.less";
</style>
